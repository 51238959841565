import { Option } from "../../../utils/DropdownUtils"

export interface SurveyListInitialFilters {
  caller: string
  callee: string
  status: string
  duration: number
  dateFrom: string
  dateTo: string
  surveySentDate: string
  additionalComments: string
  actionRequired: boolean
}

export enum SurveyTabEnum {
  ACTION_REQUIRED = "actionRequired",
  ALL = "all",
}

export enum SurveyQuestionTypeEnum {
  ADDITIONAL_COMMENTS = "ADDITIONAL_COMMENTS",
  CHANGE_OF_CONDITION = "CHANGE_OF_CONDITION",
  REACH_RECIPIENT = "REACH_RECIPIENT",
  OTHER = "OTHER",
}

export enum SurveyTypeEnum {
  CHANGE_OF_CONDITION = "CHANGE_OF_CONDITION",
  EVALUATION = "EVALUATION",
  POSTCALL_SURVEY = "POSTCALL_SURVEY",
  INCALL_SURVEY = "INCALL_SURVEY",
  BASELINE_SURVEY = "BASELINE_SURVEY",
  FOLLOW_UP_SURVEY = "FOLLOW_UP_SURVEY",
  INCALL_FOLLOWUP_SURVEY = "INCALL_SURVEY_FOLLOWUP",
}

export enum InCallSurveyStatusEnum {
  REVIEW = "Review",
  VIEW = "View",
  CONDUCT = "Conduct",
}

export interface SurveyCompleted {
  checked: boolean
  id: string
}

export type SurveyTabType = SurveyTabEnum.ACTION_REQUIRED | SurveyTabEnum.ALL
export type SelectDateTimeRangeType = { startDate: Date | null; endDate: Date | null }

export const evaluationSurveyTypesFilterOptions: Option[] = [
  {
    label: "All",
    value: "",
  },
  {
    label: "Baseline",
    value: SurveyTypeEnum.BASELINE_SURVEY,
  },
  {
    label: "Follow-up",
    value: SurveyTypeEnum.FOLLOW_UP_SURVEY,
  },
]
